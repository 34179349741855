export const SHIPPING_PROVIDERS = [
  {
    label: '17SP - 17 Special',
    value: '17sp',
  },
  {
    label: '911C - 911 Courier',
    value: '911c',
  },
  {
    label: 'A1DS - A-1 Delivery',
    value: 'a1ds',
  },
  {
    label: 'AAAC - AAA Cooper',
    value: 'aaac',
  },
  {
    label: 'AACT - AAA Cooper',
    value: 'aact',
  },
  {
    label: 'ABF - ABF Freight',
    value: 'abf',
  },
  {
    label: 'ABFS - ABF Freight',
    value: 'abfs',
  },
  {
    label: 'ACEX - Action Exprs',
    value: 'acex',
  },
  {
    label: 'ACFA - Act Fast Del',
    value: 'acfa',
  },
  {
    label: 'ACIM - ACI Motor Fr',
    value: 'acim',
  },
  {
    label: 'ACOU - A-Courier In',
    value: 'acou',
  },
  {
    label: 'ACS - American Cou',
    value: 'acs',
  },
  {
    label: 'AHS - Alb Hot Shot',
    value: 'ahs',
  },
  {
    label: 'AIRB - Airborne Exp',
    value: 'airb',
  },
  {
    label: 'AITF - AIT Freight',
    value: 'aitf',
  },
  {
    label: 'ALCO - AlohaCourier',
    value: 'alco',
  },
  {
    label: 'ALPE - Alpine Dlvry',
    value: 'alpe',
  },
  {
    label: 'ARIE - Aries',
    value: 'arie',
  },
  {
    label: 'ATSI - All Trans',
    value: 'atsi',
  },
  {
    label: 'ATTN - See Ops Mgr!',
    value: 'attn',
  },
  {
    label: 'AVEX - Averitt Expr',
    value: 'avex',
  },
  {
    label: 'AVIC - Avicom',
    value: 'avic',
  },
  {
    label: 'B&H - B&H Systems',
    value: 'b&amp;h',
  },
  {
    label: 'BEEX - BeaverExpres',
    value: 'beex',
  },
  {
    label: 'BELI - Bee-Line Del',
    value: 'beli',
  },
  {
    label: 'BFSG - Bill FSGAcct',
    value: 'bfsg',
  },
  {
    label: 'BNSF - BNSFLogistic',
    value: 'bnsf',
  },
  {
    label: 'BREL - Bright Elect',
    value: 'brel',
  },
  {
    label: 'BURL - BURLINGTON',
    value: 'burl',
  },
  {
    label: 'BW - Best Way',
    value: 'bw',
  },
  {
    label: 'CACO - Capital Cour',
    value: 'caco',
  },
  {
    label: "CADE - Carr's Dlvry",
    value: 'cade',
  },
  {
    label: 'CALI - Caliber BBI',
    value: 'cali',
  },
  {
    label: 'CASO - Carter & Son',
    value: 'caso',
  },
  {
    label: 'CC - Common Carr',
    value: 'cc',
  },
  {
    label: 'CEFR - Central Frt',
    value: 'cefr',
  },
  {
    label: 'CHR - CH Robinson',
    value: 'chr',
  },
  {
    label: 'CLLQ - Coyote Logis',
    value: 'cllq',
  },
  {
    label: 'CMS - Night Moves',
    value: 'cms',
  },
  {
    label: 'CO9 - Corporate PU',
    value: 'co9',
  },
  {
    label: 'COCO - Congo Courie',
    value: 'coco',
  },
  {
    label: 'CODO - Count Down I',
    value: 'codo',
  },
  {
    label: 'COFR - Consolidated',
    value: 'cofr',
  },
  {
    label: 'CONE - Courier Netw',
    value: 'cone',
  },
  {
    label: 'COSA - Couriers SA',
    value: 'cosa',
  },
  {
    label: 'COYO - Coyote Couri',
    value: 'coyo',
  },
  {
    label: 'CTII - CTII',
    value: 'ctii',
  },
  {
    label: 'CTR - Counter Sale',
    value: 'ctr',
  },
  {
    label: 'CTS - ContTransS12',
    value: 'cts',
  },
  {
    label: 'CUPS - Consign UPS',
    value: 'cups',
  },
  {
    label: 'CVS - GE CVS',
    value: 'cvs',
  },
  {
    label: 'CWI - Courier Work',
    value: 'cwi',
  },
  {
    label: 'CWSE - Con-Way SWEx',
    value: 'cwse',
  },
  {
    label: 'CYCS - Cyclone Cour',
    value: 'cycs',
  },
  {
    label: 'DD - DesignDirect',
    value: 'dd',
  },
  {
    label: 'DHLE - Express-12PM',
    value: 'dhle',
  },
  {
    label: 'DHLN - Next Day-3PM',
    value: 'dhln',
  },
  {
    label: 'DHLS - SecondDay5PM',
    value: 'dhls',
  },
  {
    label: 'DI - DesignInstal',
    value: 'di',
  },
  {
    label: 'DIDE - Diligent Dlv',
    value: 'dide',
  },
  {
    label: 'DISH - VenDirectShp',
    value: 'dish',
  },
  {
    label: 'DPX - DirectPckExp',
    value: 'dpx',
  },
  {
    label: 'DUPS - DirectShpUPS',
    value: 'dups',
  },
  {
    label: 'DYLT - Daylight Tra',
    value: 'dylt',
  },
  {
    label: 'DYNA - Dynamex',
    value: 'dyna',
  },
  {
    label: 'ECHS - Echo Global',
    value: 'echs',
  },
  {
    label: 'ELTR - EliteTransit',
    value: 'eltr',
  },
  {
    label: 'EMFR - Emory Freigh',
    value: 'emfr',
  },
  {
    label: 'ESTE - Estes Expres',
    value: 'este',
  },
  {
    label: 'EXAM - Expedite Ame',
    value: 'exam',
  },
  {
    label: 'EXLA - Estes Expres',
    value: 'exla',
  },
  {
    label: 'FAEX - First Air Ex',
    value: 'faex',
  },
  {
    label: 'FE2D - FedEx 2Day',
    value: 'fe2d',
  },
  {
    label: 'FE3D - FedEx 3 Day',
    value: 'fe3d',
  },
  {
    label: 'FEES - FedExExpSave',
    value: 'fees',
  },
  {
    label: 'FEFE - FedExFrtEast',
    value: 'fefe',
  },
  {
    label: 'FEFO - FedEx1STOver',
    value: 'fefo',
  },
  {
    label: 'FEFW - FedExFrtWest',
    value: 'fefw',
  },
  {
    label: 'FEGR - FedExGround',
    value: 'fegr',
  },
  {
    label: 'FEIE - FedExIntEcon',
    value: 'feie',
  },
  {
    label: 'FEPO - FedExPriorOv',
    value: 'fepo',
  },
  {
    label: 'FESB - FEDEXSTARBUC',
    value: 'fesb',
  },
  {
    label: 'FESD - FedExSameDay',
    value: 'fesd',
  },
  {
    label: 'FESO - FedExStandOv',
    value: 'feso',
  },
  {
    label: 'FLDE - Flash Dlvry',
    value: 'flde',
  },
  {
    label: 'FORA - Forward Air',
    value: 'fora',
  },
  {
    label: 'FTL - FullTrckLoad',
    value: 'ftl',
  },
  {
    label: 'FWDN - Forward Air',
    value: 'fwdn',
  },
  {
    label: 'FXNL - FXNL',
    value: 'fxnl',
  },
  {
    label: 'GHCS - GottaHaveIt',
    value: 'ghcs',
  },
  {
    label: 'GITR - G I Trucking',
    value: 'gitr',
  },
  {
    label: 'GLS - GenLogistics',
    value: 'gls',
  },
  {
    label: 'GPE - Greyhound PE',
    value: 'gpe',
  },
  {
    label: 'GREX - Gold Rush Ex',
    value: 'grex',
  },
  {
    label: 'GUPA - Gulf Parcel',
    value: 'gupa',
  },
  {
    label: 'H4EX - H4 Express',
    value: 'h4ex',
  },
  {
    label: 'HD01 - FEDX',
    value: 'hd01',
  },
  {
    label: 'HD02 - FEPL',
    value: 'hd02',
  },
  {
    label: 'HD03 - FEDX_SE',
    value: 'hd03',
  },
  {
    label: 'HD05 - FDE_SC',
    value: 'hd05',
  },
  {
    label: 'HD06 - FX2D',
    value: 'hd06',
  },
  {
    label: 'HD07 - FEDF',
    value: 'hd07',
  },
  {
    label: 'HD08 - FXFE',
    value: 'hd08',
  },
  {
    label: 'HD09 - FDEN',
    value: 'hd09',
  },
  {
    label: 'HD10 - FEDX_CG',
    value: 'hd10',
  },
  {
    label: 'HD11 - FDEG_CG',
    value: 'hd11',
  },
  {
    label: 'HD12 - FDEG',
    value: 'hd12',
  },
  {
    label: 'HD13 - FEDEXG',
    value: 'hd13',
  },
  {
    label: 'HD14 - FDXG',
    value: 'hd14',
  },
  {
    label: 'HD15 - FEDX_09',
    value: 'hd15',
  },
  {
    label: 'HD16 - FEDH',
    value: 'hd16',
  },
  {
    label: 'HD17 - FXNL',
    value: 'hd17',
  },
  {
    label: 'HD18 - FEDX_ND',
    value: 'hd18',
  },
  {
    label: 'HD19 - FDEG_ND',
    value: 'hd19',
  },
  {
    label: 'HD20 - FXND',
    value: 'hd20',
  },
  {
    label: 'HD21 - HD21',
    value: 'hd21',
  },
  {
    label: 'HDO4 - FDEG_SE',
    value: 'hdo4',
  },
  {
    label: 'HETH - Here To Ther',
    value: 'heth',
  },
  {
    label: 'HS - Hot Shot',
    value: 'hs',
  },
  {
    label: 'HSD - Heritage SD',
    value: 'hsd',
  },
  {
    label: 'HUPS - HD.com UPS',
    value: 'hups',
  },
  {
    label: 'HWC - Heritage WC',
    value: 'hwc',
  },
  {
    label: 'ISDE - Island Dlvry',
    value: 'isde',
  },
  {
    label: 'ITS - InnovativeTS',
    value: 'its',
  },
  {
    label: 'JPMC - GE JPMC',
    value: 'jpmc',
  },
  {
    label: 'JRE - J R Express',
    value: 'jre',
  },
  {
    label: 'JUMP - jump n georg',
    value: 'jump',
  },
  {
    label: 'KUNA - Kuehne Nagel',
    value: 'kuna',
  },
  {
    label: 'LRGR - LRGR',
    value: 'lrgr',
  },
  {
    label: 'LTL - LessTrckLoad',
    value: 'ltl',
  },
  {
    label: 'MAME - Marquis Msgr',
    value: 'mame',
  },
  {
    label: 'MMTL - MountainMove',
    value: 'mmtl',
  },
  {
    label: 'MOME - Modern Msngr',
    value: 'mome',
  },
  {
    label: 'NEMF - New England',
    value: 'nemf',
  },
  {
    label: 'NODS - NorcoDlvySrv',
    value: 'nods',
  },
  {
    label: 'NOWX - Now Express',
    value: 'nowx',
  },
  {
    label: 'ODFL - Old Dominion',
    value: 'odfl',
  },
  {
    label: 'OT1 - FSG Truck SA',
    value: 'ot1',
  },
  {
    label: 'OT11 - FSG Truck KC',
    value: 'ot11',
  },
  {
    label: 'OT12 - FSG Truck LA',
    value: 'ot12',
  },
  {
    label: 'OT14 - FSG Truck NJ',
    value: 'ot14',
  },
  {
    label: 'OT15 - FSG Truck DE',
    value: 'ot15',
  },
  {
    label: 'OT16 - FSG Truck AL',
    value: 'ot16',
  },
  {
    label: 'OT17 - FSG Truck PH',
    value: 'ot17',
  },
  {
    label: 'OT18 - FSG Truck EP',
    value: 'ot18',
  },
  {
    label: 'OT2 - FSG Truck AU',
    value: 'ot2',
  },
  {
    label: 'OT2A - FSG Trk AU-A',
    value: 'ot2a',
  },
  {
    label: 'OT2B - FSG Trk AU-B',
    value: 'ot2b',
  },
  {
    label: 'OT3 - FSG Truck DA',
    value: 'ot3',
  },
  {
    label: 'OT32 - FSG Truck SF',
    value: 'ot32',
  },
  {
    label: 'OT3A - FSG Trk DA-A',
    value: 'ot3a',
  },
  {
    label: 'OT3B - FSG Trk DA-B',
    value: 'ot3b',
  },
  {
    label: 'OT3C - FSG Trk DA-C',
    value: 'ot3c',
  },
  {
    label: 'OT4 - FSG Truck HO',
    value: 'ot4',
  },
  {
    label: 'OT4A - FSG Trk HO-A',
    value: 'ot4a',
  },
  {
    label: 'OT4B - FSG Trk HO-B',
    value: 'ot4b',
  },
  {
    label: 'OT4C - FSG Trk HO-C',
    value: 'ot4c',
  },
  {
    label: 'OT4D - FSG Trk HO-D',
    value: 'ot4d',
  },
  {
    label: 'OT5 - FSG Truck CC',
    value: 'ot5',
  },
  {
    label: 'OT6 - FSG Truck FW',
    value: 'ot6',
  },
  {
    label: 'OT7 - FSG Truck CH',
    value: 'ot7',
  },
  {
    label: 'OT8 - FSG Truck IN',
    value: 'ot8',
  },
  {
    label: 'OT9 - FSG Prefab',
    value: 'ot9',
  },
  {
    label: 'OVNT - Overnight CC',
    value: 'ovnt',
  },
  {
    label: 'OVTR - Ovrnght Trns',
    value: 'ovtr',
  },
  {
    label: 'PE - Pony Express',
    value: 'pe',
  },
  {
    label: 'POFF - FFA = PO Frt',
    value: 'poff',
  },
  {
    label: 'POPC - PPD/C=PO Frt',
    value: 'popc',
  },
  {
    label: 'PUCA - Purolator CA',
    value: 'puca',
  },
  {
    label: 'PXPO - Pronto Expre',
    value: 'pxpo',
  },
  {
    label: 'QUEX - Quicksilver',
    value: 'quex',
  },
  {
    label: 'R+LC - R+L Carriers',
    value: 'r+lc',
  },
  {
    label: 'RBIL - DO-NOT-SHIP',
    value: 'rbil',
  },
  {
    label: 'RBTW - C H Robinson',
    value: 'rbtw',
  },
  {
    label: 'RDFS - RDFS',
    value: 'rdfs',
  },
  {
    label: 'RDWY - YRC Freight',
    value: 'rdwy',
  },
  {
    label: 'REPU - Recycle P/U',
    value: 'repu',
  },
  {
    label: 'RICO - RvrOaks Cour',
    value: 'rico',
  },
  {
    label: 'RLGA - R&L Gators',
    value: 'rlga',
  },
  {
    label: 'RLGL - R&LGlobalLog',
    value: 'rlgl',
  },
  {
    label: 'RLNO - R+L Carriers',
    value: 'rlno',
  },
  {
    label: 'ROAD - Roadrunner',
    value: 'road',
  },
  {
    label: 'SAIA - Saia Mtr Frt',
    value: 'saia',
  },
  {
    label: 'SBAG - SBA Global',
    value: 'sbag',
  },
  {
    label: 'SD - Sales Delivr',
    value: 'sd',
  },
  {
    label: 'SEFL - Stheast Frt',
    value: 'sefl',
  },
  {
    label: 'SKCO - Sky Courier',
    value: 'skco',
  },
  {
    label: 'SMT - Sthwest Mtr',
    value: 'smt',
  },
  {
    label: 'SPDI - Special Disp',
    value: 'spdi',
  },
  {
    label: 'SSD - SS Direct',
    value: 'ssd',
  },
  {
    label: 'SSDC - Stg St DC',
    value: 'ssdc',
  },
  {
    label: 'STCO - SthTx Courie',
    value: 'stco',
  },
  {
    label: "SUEX - Suzy's Exprs",
    value: 'suex',
  },
  {
    label: 'SUPA - SunBeltParcl',
    value: 'supa',
  },
  {
    label: 'SUSH - Supershots',
    value: 'sush',
  },
  {
    label: 'TALO - Target Logis',
    value: 'talo',
  },
  {
    label: 'TMC - TMC SB FRT',
    value: 'tmc',
  },
  {
    label: 'TODE - Torralva Del',
    value: 'tode',
  },
  {
    label: 'TTEK - TransTek',
    value: 'ttek',
  },
  {
    label: 'TXEX - Texmstr Expr',
    value: 'txex',
  },
  {
    label: 'TXPK - Tex-Pack',
    value: 'txpk',
  },
  {
    label: 'UDS - United Deliv',
    value: 'uds',
  },
  {
    label: 'UPGF - UPS Freight',
    value: 'upgf',
  },
  {
    label: 'UPSA - Air-Canada',
    value: 'upsa',
  },
  {
    label: 'UPSC - Grnd-Canada',
    value: 'upsc',
  },
  {
    label: 'UPSF - UPS Freight',
    value: 'upsf',
  },
  {
    label: 'UPSG - Grnd-Commerc',
    value: 'upsg',
  },
  {
    label: 'UPSI - Internationa',
    value: 'upsi',
  },
  {
    label: 'UPSM - Mexico-Expre',
    value: 'upsm',
  },
  {
    label: 'UPSN - Next Day Air',
    value: 'upsn',
  },
  {
    label: 'UPSR - Grnd-Residen',
    value: 'upsr',
  },
  {
    label: 'UPSS - 2nd Day Air',
    value: 'upss',
  },
  {
    label: 'UPST - 3 Day Select',
    value: 'upst',
  },
  {
    label: 'UPSU - ups 3rd res',
    value: 'upsu',
  },
  {
    label: 'UPSY - Com-GrndTrac',
    value: 'upsy',
  },
  {
    label: 'UPSZ - Res-GrndTrac',
    value: 'upsz',
  },
  {
    label: 'USAT - USA Truck',
    value: 'usat',
  },
  {
    label: 'USFB - USF Bestway',
    value: 'usfb',
  },
  {
    label: 'USPS - US Post Off',
    value: 'usps',
  },
  {
    label: 'USRF - US Road Frt',
    value: 'usrf',
  },
  {
    label: 'VIEX - Vitran Expre',
    value: 'viex',
  },
  {
    label: 'VT - Vendor Truck',
    value: 'vt',
  },
  {
    label: 'WACT - WACT Deliver',
    value: 'wact',
  },
  {
    label: 'WC - Will Call',
    value: 'wc',
  },
  {
    label: 'WMLI - Watkins MLI',
    value: 'wmli',
  },
  {
    label: 'YFS - Yellow Frt',
    value: 'yfs',
  },
]

export const shippingProvidersMap = new Map(SHIPPING_PROVIDERS.map((x) => [x.value, x]))
