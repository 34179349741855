import { SHIPPING_PROVIDERS } from './shippingProviders'

const FSG_SERVICES = [
  'Electrical Wiring',
  'Lighting',
  'Smart Buildings',
  'Structured Cabling & A/V',
  'EV Charging',
  'Signs',
  'Telecom',
  'Traffic Control',
  'Solar',
]

const PROBABILITY_OPTIONS = [
  { value: 10, label: '10%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 80, label: '80%' },
]

const PHONE_TYPE = ['Mobile', 'Office', 'Home', 'Fax', 'Voice Over IP', 'Other']

const EMAIL_TYPE = ['Home', 'Work', 'Other']

const FUNCTIONAL_ROLES = ['Chief Executive', 'Decision Maker', 'Influencer', 'Buyer', 'Founder', 'Onsite Escort', 'Accounts Payable']

const LEAD_MARKETING_SOURCE = [
  'Campaign',
  'Trade Show/ Event',
  'Cold Call',
  'Existing Customer',
  'Guide Center Call',
  'Guide Center Website',
  'Referral',
  'Social Media',
  'Website',
]

const PURCHASE_TIME_FRAME = ['0-1 months', '1-3 months', '3-6 months', '6-9 months', '9 months - 1 year', '> 1 year']

const LEAD_QUALITY = [
  { label: 'Hot', value: 'Hot', icon: 'Fire', classes: 'text-white bg-color-error' },
  { label: 'Warm', value: 'Warm', icon: 'Coffee', classes: 'text-white bg-color-warning' },
  { label: 'Cold', value: 'Cold', icon: 'Snowflake', classes: 'text-white bg-color-primary-light' },
  { label: 'Dead', value: 'Dead', icon: 'Jollyroger', classes: 'text-white bg-true-black' },
]

const MARKETING_SOURCE = [...LEAD_MARKETING_SOURCE, 'Lead']

const SOCIAL_MEDIA = ['LinkedIn', 'Facebook', 'Twitter / X', 'Instagram', 'TikTok', 'Snapchat', 'Other']

const ACCOUNT_MANAGEMENT_TYPE = ['Business Managed', 'Relationship Managed', 'Territory Managed', 'Account Managed']

const INVOICE_METHODS = [
  'Customer Portal',
  'Key Enter In Portal',
  'EDI',
  'Email',
  'FAX',
  'FTP',
  'Summary Bill Mailed',
  'Summary Bill Emailed',
  'US Mail',
]

const MARKETING_GROUP = [
  { id: 'AVEN', label: 'Avendra Properties' },
  { id: 'CARR', label: 'Carr America National' },
  { id: 'CHCE', label: 'Church Coop Excluded' },
  { id: 'CHCO', label: 'Church Coop' },
  { id: 'COMM', label: 'Commercial Segment' },
  { id: 'CONT', label: 'Contractor Segment' },
  { id: 'CRES', label: 'Crescent Real Estate' },
  { id: 'CUST', label: 'Customer' },
  { id: 'DOFF', label: "Dominick's Finer Foods" },
  { id: 'FIRE', label: 'Firestone Remodels' },
  { id: 'FSG', label: 'FSG Intercompany' },
  { id: 'GRSU', label: "Grocer's Supply" },
  { id: 'HIHO', label: 'Hilton Hotels' },
  { id: 'HOSP', label: 'Hospitality/Restaurant' },
  { id: 'INDU', label: 'Industrial Segment' },
  { id: 'LIPR', label: 'Lincoln Properties' },
  { id: 'MEHO', label: 'Medical Hospital Segment' },
  { id: 'NAC', label: 'National Account Central' },
  { id: 'OCT', label: 'Octane Group Properties' },
  { id: 'POBO', label: 'Political Body Segment' },
  { id: 'SONC', label: 'Sonic Restaurant Group' },
]

const PAYMENT_METHODS = [
  { label: '3 - Credit Card', value: '3 - Credit Card' },
  { label: '7 - ACH', value: '7 - ACH' },
  { label: '1 - Check', value: '1 - Check' },
  { label: '2 - Wire', value: '2 - Wire' },
]

const SHIPPING_ORDER_DISPOSITIONS = [
  { label: 'None', value: 'None' },
  { label: 'Hold', value: 'Hold' },
  { label: 'Do Not Ship', value: 'Do Not Ship' },
  { label: 'Ship', value: 'Ship' },
]

// ! DO NOT DELETE
const CREDIT_APP_STATUS = [
  { label: 'No Application on File', value: 'No Application on File' },
  { label: 'In Review', value: 'In Review' },
  { label: 'Approved - On File', value: 'Approved - On File' },
  { label: 'Denied - On File', value: 'Denied - On File' },
]

const HOLD = ['0', '1', '2', '3', '4', '5', '6']

const ACCOUNT_MANAGEMENT_OPTIONS = [
  { label: 'BM - Business Managed', value: 'BM - Business Managed' },
  { label: 'RM - Relationship Managed', value: 'RM - Relationship Managed' },
  { label: 'TM - Territory Managed', value: 'TM - Territory Managed' },
  { label: 'AM - Account Managed', value: 'AM - Account Managed' },
]

// ! REQUIRED IN ACCOUNTS, DO NO DELETE
// ! REQUIRED IN ACCOUNTS, DO NO DELETE
const TAX_EXEMPTIONS = [
  { label: 'Church Exempt', value: 'CHURCH_EXEMPT' },
  { label: 'Direct Pay', value: 'DIRECT_PAY' },
  { label: 'Government', value: 'GOVERNMENT' },
  { label: 'Higher Education', value: 'HIGHER_EDUCATION' },
  { label: 'Marketplace Facilitator', value: 'MARKETPLACE_FACILITATOR' },
  { label: 'Non-Profit', value: 'NON_PROFIT' },
  { label: 'Out of State Shipment', value: 'OUT_OF_STATE_SHIPMENT' },
  { label: 'Political Subdivision', value: 'POLITICAL_SUBDIVISION' },
  { label: 'Recycling Service', value: 'RECYCLING_SERVICE' },
  { label: 'Resale', value: 'RESALE' },
  { label: 'State Agency', value: 'STATE_AGENCY' },
  { label: 'Generic Exemption', value: 'GENERIC_EXEMPT' },
  { label: 'Not Applicable', value: 'NOT APPLICABLE' },
]

const AUDIT_TYPES = [
  { value: 'Billable', label: 'Billable' },
  { value: 'Non-Billable', label: 'Non-Billable' },
]

const WASTE_DISPOSAL = [
  { value: 'Onsite Available', label: 'Onsite Available' },
  { value: 'Rent Dumpsters', label: 'Rent Dumpsters' },
]

const CUSTOMER_TYPE = [
  { value: 'Owner', label: 'Owner' },
  { value: 'General Contractor', label: 'General Contractor' },
]

const SALES_TYPES = [
  { value: 'New Construction', label: 'New Construction' },
  { value: 'Retrofit/Remodel', label: 'Retrofit/Remodel' },
  { value: 'Service', label: 'Service' },
  { value: 'Distribution', label: 'Distribution' },
]
const DELIVERY_METHODS = [
  { value: 'Hard Bid', label: 'Hard Bid' },
  { value: 'Design Assist', label: 'Design Assist' },
  { value: 'Design Build', label: 'Design Build' },
  { value: 'Integrated Project Delivery', label: 'Integrated Project Delivery' },
]

const SERVICE_TYPES = [
  { value: 'Turn Key', label: 'Turn Key' },
  { value: 'Material Only', label: 'Material Only' },
  { value: 'Labor Only', label: 'Labor Only' },
]

const CREDIT_CARD_SURCHARGE_TYPES = [
  { value: 'Charge', label: 'Charge' },
  { value: 'Exempt', label: 'Exempt' },
]

const DRIVING_FACTORS = [
  { value: 'Capital Improvement', label: 'Capital Improvement' },
  { value: 'Operational Savings', label: 'Operational Savings' },
  { value: 'Energy Efficiency', label: 'Energy Efficiency' },
  { value: 'Maintenance', label: 'Maintenance' },
  { value: 'Other', label: 'Other' },
]

const NEGOTIATION_TYPES = [
  { value: 'Negotiated', label: 'Negotiated' },
  { value: 'Competitive', label: 'Competitive' },
]

const VISITS_TYPES = [
  { value: 'Simple Walkthrough', label: 'Simple Walkthrough' },
  { value: 'Preliminary Survey (PA)', label: 'Preliminary Survey (PA)' },
  { value: 'Investment Grade Audit (IGA)', label: 'Investment Grade Audit (IGA)' },
]

const SMALL_BUSINESS_TYPES = [
  { value: 'Small Business', label: 'Small Business' },
  { value: 'Small Disadvantaged Business 8(a)', label: 'Small Disadvantaged Business 8(a)' },
  { value: 'Woman-Owned Small Business', label: 'Woman-Owned Small Business' },
  { value: 'HUBZone Small Business', label: 'HUBZone Small Business' },
  { value: 'Service-Disabled Veteran-Owned', label: 'Service-Disabled Veteran-Owned' },
]

const BONDS = [
  { value: 'Bid Bond', label: 'Bid Bond' },
  { value: 'Performance Bond', label: 'Performance Bond' },
  { value: 'Payment Bond', label: 'Payment Bond' },
]

const UNIQUE_INSURANCE = [
  { value: 'Condo/Residential', label: 'Condo/Residential' },
  { value: 'Affordable Housing', label: 'Affordable Housing' },
  { value: 'CCIP', label: 'CCIP' },
  { value: 'OCIP', label: 'OCIP' },
  { value: 'Other - Please Specify', label: 'Other - Please Specify' },
]

const LIQUIDATED_DAMAGE_UNITS = [
  { value: '1 Minute', label: '1 Minute' },
  { value: '15 Minutes', label: '15 Minutes' },
  { value: '30 Minutes', label: '30 Minutes' },
  { value: '45 Minutes', label: '45 Minutes' },
  { value: '1 Hour', label: '1 Hour' },
  { value: '1.5 Hours', label: '1.5 Hours' },
  { value: '2 Hours', label: '2 Hours' },
  { value: '2.5 Hours', label: '2.5 Hours' },
  { value: '3 Hours', label: '3 Hours' },
  { value: '3.5 Hours', label: '3.5 Hours' },
  { value: '4 Hours', label: '4 Hours' },
  { value: '4.5 Hours', label: '4.5 Hours' },
  { value: '5 Hours', label: '5 Hours' },
  { value: '5.5 Hours', label: '5.5 Hours' },
  { value: '6 Hours', label: '6 Hours' },
  { value: '6.5 Hours', label: '6.5 Hours' },
  { value: '7 Hours', label: '7 Hours' },
  { value: '7.5 Hours', label: '7.5 Hours' },
  { value: '8 Hours', label: '8 Hours' },
  { value: '1 Day', label: '1 Day' },
  { value: '2 Days', label: '2 Days' },
  { value: '3 Days', label: '3 Days' },
]

const ONSITE_LABOR_TYPES = [
  { value: 'Non-Union', label: 'Non-Union' },
  { value: 'Union', label: 'Union' },
  { value: 'Prevailing Wage', label: 'Prevailing Wage' },
  { value: 'Davis-Bacon', label: 'Davis-Bacon' },
]

const LABOR_SHIFTS = [
  { value: '1st Shift (9 AM - 5 PM)', label: '1st Shift (9 AM - 5 PM)' },
  { value: '2nd Shift (5 PM - 1 AM)', label: '2nd Shift (5 PM - 1 AM)' },
  { value: '3rd Shift (12 AM - 8 AM)', label: '3rd Shift (12 AM - 8 AM)' },
  { value: 'Custom', label: 'Custom' },
]

const ONSITE_STORAGE = [
  { value: 'Container(s) Allowed', label: 'Container(s) Allowed' },
  { value: '1 - 10 Pallets', label: '1 - 10 Pallets' },
  { value: '10 - 25 Pallets', label: '10 - 25 Pallets' },
  { value: '> 25 Pallets', label: '> 25 Pallets' },
  { value: 'None - Daily Deliveries Only', label: 'None - Daily Deliveries Only' },
]

const LIGHT_LEVELS = [
  { value: 'IES Standards', label: 'IES Standards' },
  { value: 'Match Existing', label: 'Match Existing' },
  { value: 'Increase', label: 'Increase' },
  { value: 'Custom Standards', label: 'Custom Standards' },
]

const CCT_PREFERENCES = [
  { value: '2700K', label: '2700K' },
  { value: '3000K', label: '3000K' },
  { value: '3500K', label: '3500K' },
  { value: '4000K', label: '4000K' },
  { value: '5000K', label: '5000K' },
  { value: '6000K', label: '6000K' },
]

const TRADE_ACT_ORIGIN = [
  { value: 'BAA - Buy American Act', label: 'BAA - Buy American Act' },
  { value: 'TAA - Trade Agreements Act', label: 'TAA - Trade Agreements Act' },
  {
    value: 'ARRA - American Recovery and Reinvestment Act',
    label: 'ARRA - American Recovery and Reinvestment Act',
  },
]

const ACCOUNT_SITE_TYPE = [
  {
    value: 'Assembly',
    label: 'Assembly',
    description:
      'Assembly Group A occupancy includes, among others, the use of a building or structure, or a portion thereof, for the gathering of persons for purposes such as civic, social or religious functions; recreation, food or drink consumption or awaiting transportation.',
  },
  {
    value: 'Business',
    label: 'Business',
    description:
      'Business Group B occupancy includes, among others, the use of a building or structure, or a portion thereof, for office, professional or service-type transactions, including storage of records and accounts. Business occupancies shall include, but not be limited to, the following: Airport traffic control towers, Ambulatory care facilities, Animal hospitals, kennels, and pounds, Banks, Barber and beauty shops, Car wash, Civic administration, Clinic, outpatient, Dry cleaning and laundry, Educational occupancies for students above the 12th grade, including higher education laboratories, Electronic data entry, Food processing establishments and commercial kitchens (not associated with restaurants), Laboratories: testing and research, Lithium-ion or lithium metal battery testing, research, and development, Motor vehicle showrooms, Post offices, Print shops, Professional services (architects, attorneys, dentists, physicians, engineers, etc), Radio and television stations, Telephone exchanges, Training and skill development; not in a school or academic program. (This shall include, but not limited to, tutoring centers, martial arts studios, gymnastics, and similar uses regardless of the ages served and where not classified as a Group A occupancy)',
  },
  {
    value: 'Educational',
    label: 'Educational',
    description:
      '"Educational Group E occupancy includes, among others, the use of a building or structure, or a portion thereof, by six or more persons at any one time for educational purposes through the 12th grade. OR - This group includes buildings and structures or portions thereof occupied by more than five children older than 21/2 years of age who receive educational, supervision or personal care services for fewer than 24 hours per day."',
  },
  {
    value: 'Factory',
    label: 'Factory',
    description:
      'Factory Industrial Group F occupancy includes, among others, the use of a building or structure, or a portion thereof, for assembling, disassembling, fabricating, finishing, manufacturing, packaging, repair or processing operations that are not classified as a Group H hazardous or Group S storage occupancy.',
  },
  {
    value: 'High-Hazard',
    label: 'High-Hazard',
    description:
      'High-hazard Group H occupancy includes, among others, the use of a building or structure, or a portion thereof, that involves the manufacturing, processing, generation or storage of materials that constitute a physical or health hazard in quantities in excess of those allowed in control areas complying with Section 414, based on the maximum allowable quantity limits for control areas set forth in Tables 307.1(1) and 307.1(2). Hazardous occupancies are classified in Groups H-1, H-2, H-3, H-4 and H-5 and shall be in accordance with this section, the requirements of Section 415 and the International Fire Code. Hazardous materials stored, or used on top of roofs or canopies, shall be classified as outdoor storage or use and shall comply with the International Fire Code.',
  },
  {
    value: 'Institutional',
    label: 'Institutional',
    description:
      'Institutional Group I occupancy includes, among others, the use of a building or structure, or a portion thereof, in which care or supervision is provided to persons who are or are not capable of self-preservation without physical assistance or in which persons are detained for penal or correctional purposes or in which the liberty of the occupants is restricted. Institutional occupancies shall be classified as Group I-1, I-2, I-3 or I-4.',
  },
  {
    value: 'Mercantile',
    label: 'Mercantile',
    description:
      '"Mercantile Group M occupancy includes, among others, the use of a building or structure or a portion thereof for the display and sale of merchandise, and involves stocks of goods, wares or merchandise incidental to such purposes and accessible to the public. Mercantile occupancies shall include, but not be limited to, the following: Department stores, Drug stores, Markets, Motor fuel-dispensing facilities, Retail or wholesale stores, Sales rooms"',
  },
  {
    value: 'Residential',
    label: 'Residential',
    description:
      'Residential Group R includes, among others, the use of a building or structure, or a portion thereof, for sleeping purposes when not classified as an Institutional Group I or when not regulated by the International Residential Code.',
  },
  {
    value: 'Storage',
    label: 'Storage',
    description:
      'Storage Group S occupancy includes, among others, the use of a building or structure, or a portion thereof, for storage that is not classified as a hazardous occupancy.',
  },
  {
    value: 'Utility and Miscellaneous',
    label: 'Utility and Miscellaneous',
    description:
      'Buildings and structures of an accessory character and miscellaneous structures not classified in any specific occupancy shall be constructed, equipped, and maintained to conform to the requirements of this code commensurate with the fire and life hazard incidental to their occupancy. Group U shall include, but not be limited to, the following: Agricultural buildings, Aircraft hangars, accessory to a one- or two-family residence (see Section 412.5), Barns, Carports, Fences more than 6 feet (1829 mm) in height, Grain silos, accessory to a residential occupancy, Greenhouses, Livestock shelters, Private garages, Retaining walls, Sheds, Stables, Tanks, Towers',
  },
]

const DLC_COMPLIANCE_TYPE = [
  { value: 'Standard DLC', label: 'Standard DLC' },
  { value: 'Premium DLC', label: 'Premium DLC' },
]

const CALIFORNIA_COMPLIANCE_TYPE = [
  { value: 'Title 20', label: 'Title 20' },
  { value: 'Title 24', label: 'Title 24' },
]

const SAFETY_COMPLIANCE_TYPE = [
  { value: 'UL', label: 'UL' },
  { value: 'cUL', label: 'cUL' },
  { value: 'CSA', label: 'CSA' },
  { value: 'ETL', label: 'ETL' },
  { value: 'cETL', label: 'cETL' },
]

const OTHER_COMPLIANCE_TYPE = [
  { value: 'Dark Sky', label: 'Dark Sky' },
  { value: 'Energy Star', label: 'Energy Star' },
]

const VOLTAGE = [
  { value: '120', label: '120' },
  { value: '208', label: '208' },
  { value: '240', label: '240' },
  { value: '277', label: '277' },
  { value: '480', label: '480' },
  { value: 'Unknown', label: 'Unknown' },
]

// ! REQUIRED IN ACCOUNTS, DO NO DELETE
// ! REQUIRED IN ACCOUNTS, DO NOT DELETE
const SPECIAL_CHARGE_OPTIONS = [
  { label: 'N/A', value: 'NOT_APPLICABLE' },
  { label: 'Travel Charge', value: 'TRAVEL_CHARGE' },
  { label: 'Service Charge', value: 'SERVICE_CHARGE' },
  { label: 'Routine Maintenance', value: 'ROUTE_MAINTENANCE' }, //ROUTE_MAINTENANCE is intentional, the endpoint expects a typo. discussed with daryl & ryan 11/9 in private slack message
]

// ! DO NOT DELETE
const NET_TERMS = [
  { label: 'Cash Sale', value: 'Cash Sale' },
  { label: 'Credit Card', value: 'Credit Card' },
  { label: 'COD', value: 'COD' },
  { label: 'Due Upon Receipt', value: 'Due Upon Receipt' },
  { label: 'Net 30 Days', value: 'Net 30 Days' },
  { label: 'Net 45 Days', value: 'Net 45 Days' },
  { label: 'Net 60 Days', value: 'Net 60 Days' },
  { label: 'Net 90 Days', value: 'Net 90 Days' },
  { label: 'Net 120 Days', value: 'Net 120 Days' },
  { label: 'WC', value: 'WC' },
]

const INVOICE_SCHEDULE = ['Daily', 'Weekly', 'Bi-Weekly', 'Monthly']

const FREIGHT_TERMS = ['FOB Origin', 'FOB Destination', 'No Charge']

// REVIEW >> Make sure you really want to use an enum and not a const.  Watch https://youtu.be/jjMbPt_H3RQ?si=J6age2ILKD50bXEx (TL;DR; at 5:00) #code-review--typescript
export enum ACTIVITY_TYPES {
  EMAIL = 'EMAIL',
  PHONE_CALL = 'PHONE_CALL',
  STATUS_CHANGE = 'STATUS_CHANGE',
  MEETING = 'MEETING',
  VIEW_ONLY = 'VIEW_ONLY',
  EDIT = 'EDIT',
}

// REVIEW >> Make sure you really want to use an enum and not a const.  Watch https://youtu.be/jjMbPt_H3RQ?si=J6age2ILKD50bXEx (TL;DR; at 5:00) #code-review--typescript
export enum ACTIVITY_ENTITIES {
  LEAD = 'leads',
  ACCOUNT = 'accounts',
  CONTACT = 'contacts',
  OPPORTUNITY = 'opportunities',
  SITE = 'sites',
}

const SEND_INVOICE_VIA = [
  'Customer Portal',
  'Key Enter in Portal',
  'EDI',
  'FAX',
  'FTP',
  'Email',
  'Summary Bill Mailed',
  'Summary Bill Emailed',
  'US Mail',
]

export const OPTIONS = {
  FSG_SERVICES,
  PHONE_TYPE,
  EMAIL_TYPE,
  FUNCTIONAL_ROLES,
  LEAD_MARKETING_SOURCE,
  MARKETING_SOURCE,
  SOCIAL_MEDIA,
  ACCOUNT_MANAGEMENT_TYPE,
  ACCOUNT_SITE_TYPE,
  MARKETING_GROUP,
  PURCHASE_TIME_FRAME,
  LEAD_QUALITY,
  PAYMENT_METHODS,
  SHIPPING_PROVIDERS,
  SHIPPING_ORDER_DISPOSITIONS,
  CREDIT_APP_STATUS,
  ACCOUNT_MANAGEMENT_OPTIONS,
  TAX_EXEMPTIONS,
  AUDIT_TYPES,
  WASTE_DISPOSAL,
  CUSTOMER_TYPE,
  SALES_TYPES,
  DELIVERY_METHODS,
  SERVICE_TYPES,
  DRIVING_FACTORS,
  NEGOTIATION_TYPES,
  VISITS_TYPES,
  SMALL_BUSINESS_TYPES,
  BONDS,
  UNIQUE_INSURANCE,
  LIQUIDATED_DAMAGE_UNITS,
  ONSITE_LABOR_TYPES,
  LABOR_SHIFTS,
  ONSITE_STORAGE,
  LIGHT_LEVELS,
  CCT_PREFERENCES,
  TRADE_ACT_ORIGIN,
  DLC_COMPLIANCE_TYPE,
  CALIFORNIA_COMPLIANCE_TYPE,
  SAFETY_COMPLIANCE_TYPE,
  OTHER_COMPLIANCE_TYPE,
  VOLTAGE,
  PROBABILITY_OPTIONS,
  SPECIAL_CHARGE_OPTIONS,
  NET_TERMS,
  ACTIVITY_TYPES,
  ACTIVITY_ENTITIES,
  HOLD,
  INVOICE_METHODS,
  INVOICE_SCHEDULE,
  SEND_INVOICE_VIA,
  FREIGHT_TERMS,
  CREDIT_CARD_SURCHARGE_TYPES,
}
