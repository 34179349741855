import React from 'react'

// react hook form validation schemas
export const VALIDATIONS = {
  REQUIRED: 'This field is required',
  PHONE_PATTERN: {
    // value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/, // * Limit to 4 digits in 3rd set
    message: 'Phone number is invalid',
  },
  EMAIL_PATTERN: { value: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/, message: 'Email address is invalid' },
  NUMBER: {
    MIN_ZERO: { value: 0, message: 'Value must be greater than or equal to zero.' },
    MAX_VALUE: (maxValue?: number | string) => {
      const parsedVal = typeof maxValue === 'string' ? parseInt(maxValue) : maxValue
      return {
        validate: (value: number | string) => {
          const parsedInputValue = typeof value === 'string' ? parseInt(value) : value
          if (parsedInputValue > parsedVal) return `Value must be greater than or equal to ${parsedVal}`
        },
      }
    },
  },

  styleLabel: (input: React.ReactNode): JSX.Element => (
    <label className="text-sm text-gray-darker">
      {input} <span className="ml-1 text-color-error">*</span>
    </label>
  ),
  styleLabelString: (input: React.ReactNode): JSX.Element => (
    <>
      {input} <span className="text-color-error">*</span>
    </>
  ),
} as const
