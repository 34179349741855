import { LeadsQuery, OpportunitiesQuery } from '../api/EntityQuery'
import { FilterQueryParams } from '../api/FilterQueryParams'

export function getMyOppsQuery(employeeNumber: string) {
  const query = new OpportunitiesQuery()
  const fq = new FilterQueryParams()

  const accountPortfolioManager = fq.textFilter({
    field: 'accountPortfolioManager.fsgEmployeeId',
    value: employeeNumber,
    operation: 'equals',
  })
  const businessDevelopmentManager = fq.textFilter({
    field: 'businessDevelopmentManager.fsgEmployeeId',
    value: employeeNumber,
    operation: 'equals',
  })
  const insideSalesRep = fq.textFilter({ field: 'insideSalesRep.fsgEmployeeId', value: employeeNumber, operation: 'equals' })
  const estimators = fq.setFilter({ field: 'estimators.fsgEmployeeId', values: [employeeNumber] })

  const isAssignedToMe = fq.group(fq.or(accountPortfolioManager, businessDevelopmentManager, insideSalesRep, estimators))

  return query.filter(isAssignedToMe)
}

export function getMyLeadsQuery(employeeNumber: string) {
  const myLeadsQuery = new LeadsQuery()
  const fq = new FilterQueryParams()
  const leadsAssignedToMe = fq.textFilter({ field: 'primaryRep.fsgEmployeeId', operation: 'equals', value: employeeNumber })
  const activeLeads = fq.setFilter({ field: 'status.status', values: ['New Lead', 'Contacting / Follow up'] })
  return myLeadsQuery.filter(fq.and(leadsAssignedToMe, activeLeads))
}


export function getAllLeadsQuery() {
  const allLeadsQuery = new LeadsQuery()
  const fq = new FilterQueryParams()
  const activeLeads = fq.setFilter({ field: 'status.status', values: ['New Lead', 'Contacting / Follow up'] })
  return allLeadsQuery.filter(activeLeads)
}