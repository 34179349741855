export const emailGreetings = [
  'Hello [Placeholder],',
  'Hi [Placeholder],',
  'Dear [Placeholder],',
  'Greetings [Placeholder],',
  'Warm greetings, [Placeholder],',
  'Hope you’re doing well, [Placeholder],',
  '[Placeholder], I hope you’ve been well,',
  'Pleasure to reach out, [Placeholder],',
  '[Placeholder], trusting you’re in good spirits,',
  'Good to connect with you, [Placeholder],',
  'Checking in, [Placeholder],',
  'Warm wishes, [Placeholder],',
  'Hope this message shines a light on your day, [Placeholder],',
  'Looking forward to our conversation, [Placeholder],',
  'Eager to touch base, [Placeholder],',
]
