import { ENDPOINTS } from '../constants/endpoints'

export abstract class EntityQuery {
  params: URLSearchParams

  constructor(private endpoint: string) {
    this.endpoint = endpoint
    this.params = new URLSearchParams()
  }

  limit(limit: string | number = 5000): EntityQuery {
    if (this.params.get('limit')) throw new Error('Cannot use multiple `limit` params')
    this.params.append('limit', typeof limit === 'string' ? limit : limit.toString())
    return this
  }

  filter(filter: string): EntityQuery {
    if (this.params.get('filter')) throw new Error('Cannot use multiple `filter` params')
    this.params.append('filter', filter)
    return this
  }

  page(page: string | number): EntityQuery {
    if (this.params.get('filter')) throw new Error('Cannot use multiple `page` params')

    this.params.append('page', typeof page === 'string' ? page : page.toString())
    return this
  }

  search(query: string): EntityQuery {
    if (this.params.get('filter')) throw new Error('Cannot use multiple `search` params')
    this.params.append('search', query)
    return this
  }

  applyDefaults() {
    // disabling the limit for now
    // if (!this.params.get('limit')) {
    //   this.params.append('limit', '5000')
    // }
  }

  getPath() {
    this.applyDefaults()
    return `${this.endpoint}?${this.params.toString()}`
  }

  getParams() {
    // ! This returns a reference to the internal `params` object, so it is mutable from outside the class
    this.applyDefaults()
    return this.params
  }
}

export class ContactsQuery extends EntityQuery {
  constructor() {
    super(ENDPOINTS.CONTACTS)
  }
}

export class OpportunitiesQuery extends EntityQuery {
  constructor() {
    super(ENDPOINTS.OPPORTUNITIES)
  }
}

export class AccountsQuery extends EntityQuery {
  constructor() {
    super(ENDPOINTS.ACCOUNTS)
  }
}

export class LeadsQuery extends EntityQuery {
  constructor() {
    super(ENDPOINTS.LEADS)
  }
}

export class SitesQuery extends EntityQuery {
  constructor() {
    super(ENDPOINTS.SITES)
  }
}

export class CampaignsQuery extends EntityQuery {
  constructor() {
    super(ENDPOINTS.CAMPAIGNS)
  }
}

export class ActivitiesQuery extends EntityQuery {
  constructor() {
    super(ENDPOINTS.ROOT_ACTIVITIES)
  }
}
